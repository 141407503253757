import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PrivacyPolicy } from './components/privacyPolicy';
import { TermsOfService } from './components/termsOfService';
import { VolXSpacePlayer } from './components/volXSpacePlayer';
import { VolXSpacePlayerInternal } from './components/volXSpacePlayerInternal';
import { POVDepthPlayer } from './components/povDepthPlayer';
import { Automation } from './components/automation';
import { VolXSpacePlayerAutomation } from './components/volXSpacePlayerAutomation';

import './App.css';

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <div>404 Not Found</div>,
    element: <VolXSpacePlayer content="https://voluspace.b-cdn.net/default_content.json" fullscreen="true" />,
  },
  {
    path: "/holoyiff",
    element: <VolXSpacePlayer content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-holoyiff&cdn=bunny&cdn_id=voluspace" id="17" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fholoyiff-ios" />,
  },
  {
    path: "/holoyiff/9",
    element: <VolXSpacePlayer content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-holoyiff&cdn=bunny&cdn_id=voluspace" info-link-1="https://x.com/HoloYiff/status/1807474008629334076" id="9" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fholoyiff-ios%3Fid%3D9" />,
  },
  {
    path: "/holoyiff/internal",
    element: <VolXSpacePlayerInternal content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-holoyiff&cdn=bunny&cdn_id=voluspace" id="17" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fholoyiff-internal-ios" />,
  },
  {
    path: "/pexels",
    element: <VolXSpacePlayer
      id="2"
      content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-pexels&cdn=bunny&cdn_id=voluspace"
      fullscreen="true"
      ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fpexels-ios"
      theme-skybox-middle="white"
      theme-panel-bg="rgba(5, 160, 129, 0.5)"
      theme-qr-bg="rgba(5, 160, 129, 0.5)"
      theme-subtext="#f7f7f7"
    />,
  },
  {
    path: "/club",
    element: <VolXSpacePlayer id="f0604" content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-club&cdn=bunny&cdn_id=voluspace" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fclub-ios" />,
  },
  {
    path: "/derpixon",
    element: <VolXSpacePlayer id="derpixon_test_of_faith" content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-derpixon&cdn=bunny&cdn_id=voluspace" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fderpixon-ios" />,
  },
  {
    path: "/vrp",
    element: <VolXSpacePlayer id="vrp_test" content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-vrp&cdn=bunny&cdn_id=voluspace" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fvrp-ios" />,
  },
  {
    path: "/viewzxr",
    element: <VolXSpacePlayer id="viewzvr_presentation" content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-viewzxr&cdn=bunny&cdn_id=voluspace" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fviewzxr-ios" />,
  },
  {
    path: "/nitro",
    element: <VolXSpacePlayer id="f0604" content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-nitro&cdn=bunny&cdn_id=voluspace" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fnitro-ios" />,
  },
  {
    path: "/holobabe",
    element: <VolXSpacePlayer id="f0043" content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-holobabe&cdn=bunny&cdn_id=voluspace" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fholobabe-ios" />,
  },
  {
    path: "/viewzxr2",
    element: <VolXSpacePlayer
      id="huhs_e274" content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-huhs&cdn=bunny&cdn_id=voluspace" fullscreen="true"
      ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fhuhs-ios" theme-skybox-top="rgb(50, 50, 50)"
      theme-skybox-middle="rgb(20, 20, 20)" theme-skybox-bottom="black" theme-grid="rgb(40, 40, 40)"
      theme-panel-bg="rgba(50, 50, 50, 0.5)" theme-qr-bg="rgba(50, 50, 50, 0.5)" theme-qr-text="white"
      theme-qr-subtext="rgb(200, 200, 200)"
    />,
  },
  {
    path: "/sashaandbaby",
    element: <VolXSpacePlayer id="sashaandbaby_joi" content="https://volxspace.com/api/request_content?cms=prismic&cms_id=volxspace-sashaandbaby&cdn=bunny&cdn_id=voluspace" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fsashaandbaby-ios" />,
  },
  {
    path: "/terms",
    element: <TermsOfService />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/pov-depth-player",
    element: <POVDepthPlayer />,
  },
  {
    path: "/automation",
    element: <Automation />,
  },
  {
    path: "/automation/view",
    element: <VolXSpacePlayerAutomation id="f0043" content="https://volxspace.duckdns.org/output/content.json" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fautomation-ios" />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
